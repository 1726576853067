<template>
  <div id="app">
    <div style="position: relative;display:flex;justify-content: center;align-items: center;">
      <div class="item" >
          <el-input v-model.trim="searchInfo.id" placeholder="请输入设备编号"></el-input>
      </div>
      <div class="item">
          <el-input v-model.trim="searchInfo.erCode" placeholder="请输入二维码编号"></el-input>
      </div>
      <div class="item">
          <el-select clearable v-model="searchInfo.typeId" filterable placeholder="请选择设备类型">
            <el-option  ref="select"  v-for="item in deviceType" :key="item.id" :label="item.name" :value="item.name" @click.native="searchDeviceTypeInfo(item)">
            </el-option>
          </el-select>
      </div>
      <div class="item">
          <el-cascader 
             placeholder="请选择公司名称"
             filterable v-model="searchInfo.ciId" 
             :show-all-levels="false" 
             :options="allCompanyList" 
             :props="defaultProps" 
             @change="searchDeviceCompanyInfo($event)" 
             ref="cascader1"
             clearable
            >
          </el-cascader>
      </div>
      <div class="item">
           <el-date-picker 
            @change="handlChangeTime" 
            style="width:auto" 
            v-model="overhaulTime" 
            type="datetimerange" 
            value-format="yyyy-MM-dd HH:mm:ss" 
            start-placeholder="检修开始日期" 
            end-placeholder="检修结束日期"
            >
          </el-date-picker>
      </div>
      <div class="item">
           <el-date-picker  
           @change="handlChangeTime1"  
           style="width:auto" 
           v-model="scrapTime" 
           type="datetimerange" 
           value-format="yyyy-MM-dd HH:mm:ss" 
           start-placeholder="报废开始日期" 
           end-placeholder="报废结束日期">
          </el-date-picker>
      </div>
      <div class="item">
          <el-button icon="el-icon-search" type="primary" @click="searchDeviceInfo">搜索</el-button>
      </div>
      <div class="item">
          <el-button icon="el-icon-refresh" type="primary" @click="reset">重置</el-button>
      </div>
      <div class="item">
           <el-upload v-if="userRightList.updateJuris=='1'" :show-file-list="false" :before-upload="beforeUpload" :on-success="onSuccess" :on-error="onError" class="upload-demo impt" :action="
            wechatName +
            'iotechserver/DeviceManagementDeviceInfoController/excel/excelImport'
          " multiple>
            <el-button :icon="importData.iconImport" :disabled="importData.disabledImport" type="success" class="headerButton">{{ importData.butTextImport }}</el-button>
          </el-upload>
      </div>
      <div class="item">
          <el-button :icon="importData.iconExport" :disabled="importData.disabledExport" type="success" class="headerButton" @click="exportAll">{{ importData.butTextExport }}
          </el-button>
      </div>
      <div class="item" style="margin-left:auto;width:15%" >
            <el-select v-model="tableTitle" multiple collapse-tags placeholder="选择类型" @change="selectTitle">
            <el-option v-for="item in tableTitleList" :key="item.value" :label="item.title" :value="item.title" >
            </el-option>
          </el-select>
      </div>
    </div>

    <el-table :stripe="true" :data="deviceInfoList" style="width: 100%">
      <el-table-column type="expand">
        <template slot-scope="props">
          <div style="padding-left:20px">
            <el-form label-position="left" inline class="demo-table-expand">
              <el-form-item v-for="item in  props.row.deviceManagementDeviceStaticProperties" :label="item.name+'：'">
                <span>{{item.value}}</span>
              </el-form-item>
            </el-form>
            <div class="question">
              <div>
                <div v-for="(item,index) in props.row.devicePatrolQuestion" class="ques-item">
                  {{item}}：
                </div>
              </div>
              <div>
                <div v-for="(item,index) in props.row.devicePatrolValue" class="ques-item">
                  {{item=='1'?'是':'否'}}
                </div>
              </div>
            </div>
          </div>
        </template>
      </el-table-column>
      <el-table-column v-if="tableTitle.includes('设备编号')" label="设备编号" required prop="id" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('二维码ID')" label="二维码ID" required prop="erCode" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('设备类型')" label="设备类型" prop="typeId" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('单位')" label="单位" prop="ciId" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('检修时间')" label="检修时间" prop="overhaulTime" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('检修周期')" label="检修周期" prop="overhaulCycle" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('报废时间')" label="报废时间" prop="scrapTime" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('报废前提醒')" label="报废前提醒" prop="scrapRemind" align="center"></el-table-column>
      <el-table-column v-if="tableTitle.includes('检修次数')" label="检修次数" prop="overhaulSum" align="center"></el-table-column>
      <el-table-column label="操作" align="center" v-if="userRightList.updateJuris=='1'">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="editDeviceInfo(scope.row)">编辑</el-button>
        </template>
      </el-table-column>
    </el-table>

    <!-- 编辑弹框 -->
    <el-dialog :title="editList.typeId" :visible.sync="dialogFormVisible" width="35%" @close="closeEdit" style="background:rgba(0,0,0,0.6)">
      <div style="height:300px; overflow:scroll;scrollbar-width:none">
        <el-form label-position="left" label-width="100px">
          <el-form-item label="设备ID">
            <el-input v-model="editList.id" :disabled="true"></el-input>
          </el-form-item>
          <el-form-item label="设备类型">
            <el-select v-model="editList.typeId" placeholder="请选择设备类型" style="width:270px" >
              <el-option  ref="select" v-for="item in deviceType" :key="item.id" :label="item.name" :value="item.name" @click.native="selectDeviceType(item)">
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item v-for="item in staticInfo1" :label="item.name" :key="item.name">
            <el-input v-model="item.value" :value="item.value"></el-input>
          </el-form-item>
          <el-form-item v-for="item in editList.deviceTypeStatic" :label="item.proName" :key="item.name">
            <el-input v-model="item.value" :value="item.value"></el-input>
          </el-form-item>

          <el-form-item label="当前单位">
            <el-tag type="primary">{{editList.ciId}}</el-tag>
          </el-form-item>

          <el-form-item label="单位名称">
            <el-cascader 
              :show-all-levels="false"
              placeholder="请选择要修改的公司名称，不选默认为当前公司" 
              clearable
              ref="cascader" 
              :options="allCompanyList" 
              @change="checkedCompany" 
              :props="defaultProps" 
              style="width:100%"
            >
            </el-cascader>
          </el-form-item>

          <el-form-item label="检修时间">
            <el-date-picker v-model="editList.overhaulTime" type="datetime" default-time style="width:100%" @change="changeTime($event)">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="检修周期">
            <el-input v-model="editList.overhaulCycle" type="number"></el-input>
          </el-form-item>
          <el-form-item label="报废时间">
            <el-date-picker v-model="editList.scrapTime" type="datetime" style="width:100%" @change="changeTime1($event)">
            </el-date-picker>
          </el-form-item>
          <el-form-item label="报废前提醒">
            <el-input v-model="editList.scrapRemind" type="number"></el-input>
          </el-form-item>
        </el-form>
      </div>

      <div slot="footer" class="dialog-footer">
        <el-button @click="closeEdit">取 消</el-button>
        <el-button type="primary" @click="submitInfo">确 定</el-button>
      </div>
    </el-dialog>

    <!-- 分页 -->
    <div>
      <el-pagination :current-page.sync="deviceInfo.current" :page-size="deviceInfo.limit" :total="total" layout="total, prev, pager, next, jumper" @current-change="handleCurrentChange" class="pagination"></el-pagination>
    </div>
  </div>
</template>

<script>
import deviceInfo from '@/api/facilitiesManagement/DeviceInfo'
import companySystem from "@/api/managementApi/CompanyInfo";
import baseData from "../../assets/config/BaseData";
// 查权限
import LoginUserJuris from '@/api/managementApi/LoginUserJuris'
// 查账号
import user from "@/api/manageApi/User";
// vuex
// import { mapState} from 'vuex'
export default {
  name: "DeviceInfo",
  data() {
    return {
      tableTitleList: [{
        id: 0,
        title: '设备编号',
      }, {
        id: 1,
        title: '二维码ID',
      }, {
        id: 2,
        title: '设备类型',
      }, {
        id: 3,
        title: '单位',
      }, {
        id: 4,
        title: '检修时间',
      }, {
        id: 5,
        title: '检修周期',
      }, {
        id: 6,
        title: '报废时间',
      }, {
        id: 7,
        title: '报废前提醒',
      }, {
        id: 8,
        title: '检修次数',
      }],
      tableTitle: ['设备编号', '二维码ID', '设备类型', '单位', '检修时间', '检修周期', '报废时间', '报废前提醒', '检修次数'],

      choseTime: '',

      searchInfo: {
        ciId: '',  // 根据公司查询
        typeId: '',   // 根据设备类型查询
        id: '',  // 根据设备ID查询
        erCode: '',     // 根据 二维码ID查询
        scrapTime:"",// 根据报废开始时间查询
        scrapEndTime:"", //根据报废结束时间查询
        overhaulTime:"",  //根据检修开始时间查询
        overhaulEndTime:"",//根据检修结束时间查询
      },

      //   时间格式化
      year: '',
      month: '',
      day: '',
      hour: '',
      minute: '',
      seconds: '',
      fullTime: '',

      // 静态属性
      staticInfo: [],
      staticInfo1: [],
      // 设备类型
      deviceType: [],
      //   选择的设备类型 id
      deviceTypeOfId: "",

      //   所有公司
      companyList: [],

      defaultProps: {
        children: "smallCompanyList", // 子公司节点
        label: "name", // 公司名
        value: "id",
        checkStrictly: true,
      },
      // 公司ID
      companyId: "",
      companyName: "",

      deviceTitle: '',
      dialogFormVisible: false,
      //   当前定位页码
      deviceInfo: {
        limit: 10,
        current: 1,
      },

      total: 1, // 总数

      editList: [],
      deviceInfoList: [],
      creatTime:"",
      importData: {
        //导入数据 工具对象
        butTextImport: "批量导入",
        butTextExport: "批量导出",
        iconImport: "el-icon-download",
        iconExport: "el-icon-upload2",
        disabledImport: false,
        disabledExport: false,
      },

      static: {},
      staticList: [],
      baseUrl: baseData.baseUrl,
      wechatBaseUrl: baseData.wechatBaseUrl,
      wechatName: baseData.wechatName,

      userRightList: [],
      //报废开始时间
      scrapTime:"",
      //检修开始时间
      overhaulTime:"",  
      allCompanyList:this.$store.getters.allCompanyList
    };
  },
  created() {
    this.getUsers()

    this.getDeviceInfo()

    this.getDeviceType()

  },
  // computed: {
  //   ...mapState({'allCompanyList':state=>state.companyInfo.allCompanyList})
  // },

  methods: {
    
    selectTitle(value) {
      if (value.length < 1) {
        this.$message.warning("至少选择1个类型")
        this.tableTitle.push('设备编号')
      } else {
        this.tableTitle = value
      }
    },
    handlChangeTime(e){
      console.log(e);
      if(this.overhaulTime != null  && this.overhaulTime != undefined){
        
        const [overhaulStartTime,overhaulEndTime] = this.overhaulTime;
        this.searchInfo.overhaulTime = overhaulStartTime;
        this.searchInfo.overhaulEndTime = overhaulEndTime;
        return
      }else {
        this.searchInfo.overhaulTime = null;
        this.searchInfo.overhaulEndTime = null;
      }
    },
    handlChangeTime1(e){
      console.log(e);
      if(this.scrapTime != null && this.scrapTime != undefined){
        const [scrapStartTime,scrapEndTime] = this.scrapTime;
        this.searchInfo.scrapTime = scrapStartTime;
        this.searchInfo.scrapEndTime = scrapEndTime;
        return
      }else {
        this.searchInfo.overhaulTime = null;
        this.searchInfo.overhaulEndTime = null;
      }
    },

    // 获取用户权限
    async getUserRight(user) {
      this.userRightList = []
      const { data: res } = await LoginUserJuris.getMenuFromUserName(user)
      this.userRightList = res.data.jurisList
      if (this.userRightList.length == 0) {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      } else {
        for (let i = 0; i < res.data.jurisList.length; i++) {
          if (res.data.jurisList[i].menuName == "DeviceInfo") {
            this.userRightList = res.data.jurisList[i]
          }
        }
      }
      console.log(this.userRightList);
      if (user == "admin") {
        this.userRightList.addJuris = 1
        this.userRightList.delJuris = 1
        this.userRightList.updateJuris = 1
      }
    },
    // 获取用户名
    getUsers() {
      user.loginUserInfo().then(res => {
        this.getUserRight(res.data.data.userName)
      })
    },


    //   分页查询所有设备信息
    async getDeviceInfo() {
      console.log("查询的时候传递的参数this.searchInfo",this.searchInfo);

      const { data: res } = await deviceInfo.pageDeviceManagementDeviceInfo80(this.deviceInfo, this.searchInfo)
      this.total = res.data.total;
      this.deviceInfoList = res.data.deviceManagementDeviceInfoVoList

      for (let i = 0; i < this.deviceInfoList.length; i++) {
        this.staticList = [];
        for (let j = 0; j < this.deviceInfoList[i].deviceStaticName.length; j++) {
          this.static = {
            name: this.deviceInfoList[i].deviceStaticName[j],
            value: this.deviceInfoList[i].deviceStaticValue[j],
          }
          this.staticList.push(this.static)
        }
        this.deviceInfoList[i].deviceManagementDeviceStaticProperties = [];
        this.deviceInfoList[i].deviceManagementDeviceStaticProperties = this.staticList;
      }
      this.getCompanyInfo()
      // this.searchInfo = {}
    },

    closeEdit() {
      this.getDeviceInfo()
      this.dialogFormVisible = false
    },

    // 所有公司
    async getCompanyInfo() {
      const { data: res } = await companySystem.getCompanyInfo()
      this.companyList = res.data.list;
      for (let i = 0; i < this.deviceInfoList.length; i++) {
        let isCiId = true
        for (let j = 0; j < this.companyList.length; j++) {
          if (this.deviceInfoList[i].ciId == this.companyList[j].id) {
            this.deviceInfoList[i].ciId = this.companyList[j].name;
            isCiId = false
          } 
        }
        if(isCiId){
          this.deviceInfoList[i].ciId = "无"
        }
      }
    },
    // 选择的公司
    checkedCompany() {
      this.companyId = this.$refs.cascader.getCheckedNodes()[0].data.id;
      this.companyName = this.$refs.cascader.getCheckedNodes()[0].data.name;
      // 选择后让选择框消失
      this.$refs.cascader.dropDownVisible = false;
    },

    //  根据设备类型查询 
    searchDeviceTypeInfo(item) {
      console.log(item);
      this.searchInfo.typeId = item.name
      console.log(this.searchInfo.typeId);
    },

    // 根据公司名称查询 
    searchDeviceCompanyInfo(val) {
      let ciid ,a;
      if (val) {
         if (val.length <= 1) [ciid] = val 
          else [{...a},ciid] = val

          this.searchInfo.ciId = ciid
          this.$refs.cascader1.dropDownVisible = false;
      }
    },

    // 根据设备id 和 二维码id 查询
    searchDeviceInfo() {

      this.getDeviceInfo()
    },
    reset() {
      this.searchInfo.id = ''
      this.searchInfo.erCode = ''
      this.searchInfo.typeId = ''
      this.searchInfo.overhaulEndTime="";
      this.searchInfo.overhaulTime = "";
      this.searchDeviceInfo.scrapEndTime = "";
      this.searchDeviceInfo.scrapTime = "";
      this.searchInfo.ciId = ''
      this.overhaulEndTime = "";
      this.overhaulTime = "";
      this.scrapEndTime = "";
      this.scrapTime = "";
      this.getDeviceInfo()
    },


    // 查询设备类型
    getDeviceType() {
      deviceInfo.getDeviceManagementType().then(res => {
        this.deviceType = res.data.data.list;
      })
    },

    // 分页
    handleCurrentChange(val) {
      this.getDeviceInfo(val)
    },

    // 编辑
    editDeviceInfo(row) {
      this.dialogFormVisible = true;

      this.companyName = row.ciId
      console.log(row);

      this.editList = row;

      this.staticInfo1 = [];
      for (let j = 0; j < row.deviceStaticName.length; j++) {
        this.static = {
          name: row.deviceStaticName[j],
          value: row.deviceStaticValue[j],
        }
        this.staticInfo1.push(this.static)
      }
      this.editList.staticValueList = [];
      this.editList.staticValueList = this.staticInfo1;
    },

    // },

    // 选择的设备类型
    selectDeviceType(item) {
      this.deviceTypeOfId = item.id;
      this.staticInfo1 = [];

      // 根据设备类型查静态属性
      deviceInfo.getDeviceManagementTypeById(this.deviceTypeOfId).then(res => {
        this.editList.deviceTypeStatic = res.data.data.deviceManagementTypePro
      })


    },

    changeTime(event) {
      console.log(event);
      var chinaStandard = event
      var date = new Date(chinaStandard);
      var y = date.getFullYear();
      var m = date.getMonth() + 1;
      m = m < 10 ? ('0' + m) : m;
      var d = date.getDate();
      d = d < 10 ? ('0' + d) : d;
      var h = date.getHours();
      var minute = date.getMinutes();
      var seconds = date.getSeconds();
      minute = minute < 10 ? ('0' + minute) : minute;
      let time = y + '-' + m + '-' + d + ' ' + h + ':' + minute + ':' + seconds;

      console.log(time);
      this.editList.overhaulTime = time
    },

    changeTime1(event1) {
      console.log(event1);
      var chinaStandard1 = event1
      var date1 = new Date(chinaStandard1);
      var y1 = date1.getFullYear();
      var m1 = date1.getMonth() + 1;
      m1 = m1 < 10 ? ('0' + m1) : m1;
      var d1 = date1.getDate();
      d1 = d1 < 10 ? ('0' + d1) : d1;
      var h1 = date1.getHours();
      var minute1 = date1.getMinutes();
      var seconds1 = date1.getSeconds();
      minute1 = minute1 < 10 ? ('0' + minute1) : minute1;
      let time1 = y1 + '-' + m1 + '-' + d1 + ' ' + h1 + ':' + minute1 + ':' + seconds1;

      console.log(time1);
      this.editList.scrapTime = time1
    },

    // 编辑信息确认按钮
    submitInfo() {


      this.editList.staticValueList = [];
      this.editList.staticValueList = this.staticInfo1;
      this.$confirm('确认修改设备信息，此操作将不可撤回, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.editList.ciId = this.companyId
        this.editList.ciId = this.companyName
        this.editList.deviceManagementDeviceStaticProperties = [];
        console.log(this.editList);

        deviceInfo.updataDeviceManagementDeviceInfo(this.editList).then(res => {
          this.$message({
            type: 'success',
            message: res.data.message
          });
          console.log(res);
          this.getDeviceInfo()
        })
        this.dialogFormVisible = false;
      })
    },

    //上传文件之前的钩子
    beforeUpload() {
      //上传文件之前的钩子
      this.importData.iconImport = "el-icon-loading";
      this.importData.butTextImport = "正在导入";
      this.importData.disabledImport = true;
    },

    onSuccess(response) {
      //上传文件成功的钩子
      this.importData.disabledImport = false;
      this.importData.iconImport = "el-icon-download";
      this.importData.butTextImport = "批量导入";
      if (response.code == 20000) {
        if (response.message == "导入成功") {
          this.$message.success(response.message);
        } else {
          this.$message.error(response.message)
        }
      }
      if (response.code == 20001) {
        this.$message.error(response.message);
      }
      this.getDeviceInfo();
    },
    onError() {
      //上传文件失败的钩子
      this.importData.disabledImport = false;
      this.importData.iconImport = "el-icon-download";
      this.importData.butTextImport = "批量导入";
      this.$message.error("导入失败！");
    },
    // 导出
    exportAll() {
      //批量导出  这里能成功请求后端接口并返回数据 但是后端响应的是二进制数据，这里只能接收JSON等数据。

      this.importData.butTextExport = "正在下载";
      this.importData.iconExport = "el-icon-loading";
      this.importData.disabledExport = true;

      //  window.open("/nechserver/valAnalyticRule/excelExport"); 下面的方式更协调

      //创建一个FORM表单，提交表单的方式下载
      var studentName = 1;
      var form = document.createElement("form");
      form.id = "form";
      form.name = "form";
      //把这个form放在body里面

      document.body.appendChild(form);
      //给form里面创建一个input框，隐藏掉，来存参数
      var input = document.createElement("input");
      input.type = "hidden";
      input.name = "studentName";
      input.value = studentName;
      var input1 = document.createElement("input");
      input1.type = "hidden";
      input1.name = "token";
      input1.value = window.localStorage.getItem("iotechtj_token");

      //把input放在form里面
      form.appendChild(input);
      form.appendChild(input1);
      form.method = "RequestMethod.get"; //请求方式
      form.action =
        // this.wechatBaseUrl + '/iotechserver/DeviceManagementDeviceInfoController/excel/excelExport';
        this.wechatName + 'iotechserver/DeviceManagementDeviceInfoController/excel/excelExport';
      form.submit();
      //删掉form
      document.body.removeChild(form);

      this.interval = setInterval(() => {
        this.getCookie();
      }, 200);
    },
    getCookie() {
      //获取cookie
      console.log("获取到ISOVER", this.$Cookies.get("ISOVER"));

      if (
        this.$Cookies.get("ISOVER") != undefined &&
        this.$Cookies.get("ISOVER") == "1"
      ) {
        this.importData.butTextExport = "批量导出";
        this.importData.iconExport = "el-icon-upload2";
        this.importData.disabledExport = false;
        this.$Cookies.set("ISOVER", "0");
        clearInterval(this.interval);
      }
    },



  },
};
</script>

<style lang="scss" scoped>
.rows {
  margin:  10px 5px;
}
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 25%;
}

.question {
  width: 100%;
  //   border: 1px solid red;
  display: flex;

  .ques-item {
    width: 100%;
    height: 35px;
    line-height: 30px;
  }
}
.item {
  margin: 20px 5px;
}
</style>
